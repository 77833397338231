<template>
    <div class="findBlueTooth">
        <div class="deviceInfo">
            <div class="left">
                <div class="name textEllipsis">{{ dataInfo.name }}</div>
                <div class="other textEllipsis">{{ dataInfo.categoryName }}</div>
                <div class="other textEllipsis">{{ dataInfo.brandName }}</div>
            </div>
            <div class="right">
                <div class="subItem">
                    <span class="label">{{$VUEAPPDEVICECODE()}}</span><span class="value">{{ dataInfo.code }}</span>
                </div>
                <div class="subItem">
                    <span class="label">卡片号</span><span class="value">{{ dataInfo.label }}</span>
                </div>
                <div class="subItem">
                    <span class="label">定位</span><span class="value">{{ dataInfo.location?dataInfo.location.place:'-' }}</span>
                    <span class="blueTimestamp">{{ formatTimeDurationToSecond(dataInfo.blueTimestamp) }}</span>
                </div>
            </div>
        </div>
        <div id="map" class="deviceMap">

        </div>
    </div>
</template>

<script>
import SearchMapView from '@/views/searchDevice/searchMapView/searchMapView'
export default {
    name: 'findBlueTooth',
    components: {  SearchMapView },
    data() {
        return {
            query: '',
            dataInfo: {}
        }
    },
    created() {
        // this.dataInfo = this.$route.query
    },
    methods: {

    }
}
</script>
<style lang='scss' scoped>
@import '@styles/variables.scss';

.findBlueTooth {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;

    .deviceInfo {
        display: flex;
        font-size: 12px;
        padding: 8px 0;
        color: #666666;
        .left {
            width: 30%;
            padding: 0 8px;
            text-align: center;
            .name {
                font-size: 14px;
                color: #333333;
            }
        }

        .right {
            flex: 1;
                padding-right: 8px;
            .subItem{
                .label{
                    width: 60px;
                    display: inline-block;
                    text-align: justify;
                    text-align-last: justify;
                    padding-right: 8px;
                }
                .blueTimestamp{
                    float: right;
                }
            }
            
        }
    }

    .deviceMap {
        flex: 1;
        background: #999999;
    }
}</style>